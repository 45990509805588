<template>
  <div class="Addfriends">
    <div class="top-title">
      <div class="top-text">添加合同表单</div>
    </div>


   <div class="table-tap">
       <ul>
          <li v-for="(item,i) in taplist" :key="i"> <span @click="tap(item)" :class="classactive == item.tapid?'active':''">{{item.label}}</span></li>
        </ul>
    </div>
<!-- 表单 -->
    <div class="addfriends-box">
      <el-form ref="form" :model="lists" label-width="100px">
        <el-form-item label="标题" >
          <el-input v-model="listpar.title" style="width:200px"></el-input>
        </el-form-item>

        <el-form-item label="内容">
          <el-input type="textarea" v-model="listpar.content" style="width:600px" rows="10"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit()">修改</el-button>
        </el-form-item>
      </el-form>
    </div>
<!-- 展示 -->
    <!-- <div class="addfriends-show">
        <div class="addfriends-show-box">
          <div class="biaoti">
           <span>标题：</span> {{listpar.title}}
          </div>
          <div class="neirong">
           <span>内容：</span> {{listpar.content}}
          </div>
        </div>
    </div> -->
  </div>
</template>
<script>
// 假数据
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js";

export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 假数据
      listpar:{},
      lists: {},
      loading: this.$common.loading,

      classactive:0,
       taplist:fakelist.agency.one,
    };
  },
  created(){
        this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'ContractEntity',
        catalog:this.classactive,
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
          this.loading = false
      },
              //弹窗
      onSubmit(){
          this.$confirm('是否确认修改', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.onSubmits()
          }).catch(action => {
            console.log("取消");
          })
      },

     //提交接口
      async onSubmits(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'upsert',
        bizCatalog:'ContractEntity',
        bizData:{
            catalog:this.classactive,//代理合同
            title:this.listpar.title,
            content:this.listpar.content,
        }
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
        this.$alert('修改成功', '消息提示', {confirmButtonText: '确定'});
        this.getSong();
      },

        // 选项卡
      tap(e){
        this.classactive = e.tapid
       this.getSong();
      },

  },
};
</script>


<style  scoped>
.top-title {
  width: 94%;
  height: 100px;
  margin: 0 auto;
}
.top-text {
  font-size: 18px;
}
.addfriends-box{
  padding-top: 50px;
  width: 1000px;
  margin: 0 auto;
}
.addfriends-show{
  width: 98%;
  margin: 0 auto;
  border-top: 2px solid #999;
}

.addfriends-show-box{
    width: 900px;
    margin: 0 auto;
    padding-top: 50px;
}
.addfriends-show-box div{
    width: 650px;
   padding-top: 20px;
   font-size: 14px;
}
.addfriends-show-box div span{
 font-size: 16px;
  color: rgba(255, 141, 26, 1);
}

.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 200px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}

</style>